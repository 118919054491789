.history-title {
	color: #000000;
	font-style: normal;
	font-weight: 500;
	font-size: 23px;
	line-height: 24px;
	letter-spacing: 0.01em;
}

.history-container {
	padding: 20px;
}

.patient-page-name{
    font-weight: 27px;
    font-size: 27px;
    color : #252525;
}

.patient-page-right-description{
    font-weight : 500;
    font-size: 17px;
    line-height: 28px;
    color : "#8E8E8"
}

.patient-page-left-description {
    font-weight : 500;
    font-size: 17px;
    line-height: 28px;
    color : #424242;
}

.prescriptiion-form-heading {
    font-weight: 500;
    font-size: 25px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 50px;
    margin-top: 50px;
}

.patient-tab-wrapper {
    background: #EDEDEE;
    display: flex;
    width: 219px;
    height: 43px;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px;
    border-radius: 14px;
}

.patient-tab-wrapper-item-prescription {
    display : flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 11px;
    height: 30px;
    width: 100px;
    color: #4D63DD;
    padding: 5px;
}

.active-tab {
    background: #FFFFFF;
    border-radius: 11px;
    height: 30px;
    width: 100px;
    color: #4D63DD;
    padding: 5px;
    border : none;
}

.patient-tab-wrapper-item-history {
    display : flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 11px;
    height: 30px;
    color: #4D63DD;
    padding: 5px;
    width: 70px;
    font-size: 15px;
    line-height: 28px;
}
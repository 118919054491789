/* Works on Firefox */
.chatDashboard {
  scrollbar-width: thin;
  scrollbar-color: #979696 transparent;
  font-family: Inter;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #979696;
  border-radius: 12px;
}

.sider-container-column {
	padding-top: 20px;
	padding-left: 20px;
	padding-bottom: 20px;
}
.sider-container-column-2 {
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
}

.sider-title-name {
	color: #252525;
	font-weight: 500;
	font-size: 20px;
	line-height: 20px;
}

.sider-age-gender-dob {
	color: #8e8e8e;
	font-size: 15px;
	line-height: 20px;
}

.sider-mobile {
	color: #8e8e8e;
	font-size: 15px;
	line-height: 20px;
}

.sider-email {
	color: #8e8e8e;
	font-size: 15px;
	line-height: 20px;
}

.sider-heading {
	color: #4a4a4a;
	font-size: 15px;
	line-height: 28px;
}

.user-details-container {
	margin-top: 15px;
}

.tab-selector-base {
	border-radius: 11px;
	font-size: 16px;
	padding: 12px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin: 2px 0px;
	user-select: none;
	cursor: pointer;
}
.tab-selector-base img {
	height: 20px;
	width: auto;
}

.tab-selector-base.tab-selected {
	background-color: #4d63dd;
	color: white;
	fill: white;
	font-weight: 600;
}
.tab-selector-base.tab-selected svg * {
	fill: white;
}

.name-pic-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

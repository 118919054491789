.grid {
  display: flex;                       /* establish flex container */
  flex-wrap: wrap;                     /* enable flex items to wrap */
  justify-content: space-around;
  margin-top: 5%;
  
}
.cell {
  flex: 0 0 32%;                       /* don't grow, don't shrink, width */
  height: 50vh;
  margin-bottom: 5px;
  background-color: #FFF;
}
.cell:nth-child(3n) {
  background-color: #FFF;
}
.Revenue{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    margin: 3vh 3vh 2vh 5vh;
}
.chart{
    height: auto;
    width: 74vh;
    background-color: white;
    margin-left: 5vh;
    margin-right: 5vh;
    border-radius: 2vh;
}
.select_month{
    float: right;
}
@media only screen and (max-width: 660px) {
    .Revenue{
        display: block;
        
    }
}
@media only screen and (max-width: 660px) {
    .Revenue{
        display: block;
        
    }
}
@media only screen and (max-width: 550px) {
    .chart{
        width: 70%;
        
    }
}